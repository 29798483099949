<template>
    <div class="pad-3">
      <div class="bb-container bb-container--lista">
        <h1 class="header-1">{{ $t("privacyPolicy.privacyPolicy", lang) }}</h1>
        <div class="post-entry">
          <h3>{{ $t("privacyPolicy.responsible", lang) }}</h3>
          <p>
            {{ $t("privacyPolicy.responsibleText", lang) }}
          </p>
          <h3>{{ $t("privacyPolicy.contactInfo", lang) }}</h3>
          <ul>
            <li>{{ $t("privacyPolicy.contactIdentity", lang) }}: HelloBB S.L- CIF: B-09621285</li>
            <li>
              {{ $t("privacyPolicy.contactAddress", lang) }}: C/ Pompeu Fabra 36, 4rt 6a , CP:43500, Tortosa
            </li>
            <li>{{ $t("privacyPolicy.contactEmail", lang) }}: hola@hellobb.net</li>
            <li>{{ $t("privacyPolicy.delegate", lang) }}: hola@hellobb.net</li>
          </ul>
  
          <h3>{{ $t("privacyPolicy.purpose", lang) }}</h3>
          <p>
            {{ $t("privacyPolicy.purposeText1", lang) }}
          </p>
          <ul>
            <li>
              {{ $t("privacyPolicy.purposeText11", lang) }}
            </li>
            <li>
              {{ $t("privacyPolicy.purposeText12", lang) }}
            </li>
            <li>
              {{ $t("privacyPolicy.purposeText13", lang) }}
            </li>
          </ul>
          <p>
            {{ $t("privacyPolicy.purposeText2", lang) }}
          </p>
  
          <h4>{{ $t("privacyPolicy.googleRecopilation", lang) }}</h4>

          <p>{{ $t("privacyPolicy.googleRecopilationText1", lang) }}</p>

          <p>
            {{ $t("privacyPolicy.googleRecopilationText2", lang) }}
          </p>

          <p>{{ $t("privacyPolicy.googleRecopilationText3", lang) }}</p>

          <p>{{ $t("privacyPolicy.googleRecopilationText4", lang) }}</p>
          <ul>
            <li>{{ $t("privacyPolicy.googleRecopilationText41", lang) }}</li>
            <li>{{ $t("privacyPolicy.googleRecopilationText42", lang) }}</li>
          </ul>

          <p>{{ $t("privacyPolicy.googleRecopilationText5", lang) }}</p>

          <p>{{ $t("privacyPolicy.googleRecopilationText6", lang) }}</p>

          <h4>{{ $t("privacyPolicy.legend", lang) }}</h4>
  
          <p>
            {{ $t("privacyPolicy.legendText", lang) }}
          </p>
  
          <h4>{{ $t("privacyPolicy.recipients", lang) }}</h4>
  
          <p>
            {{ $t("privacyPolicy.recipientsText", lang) }}
          </p>
  
          <h4>{{ $t("privacyPolicy.rights", lang) }}</h4>
  
          <p>
            {{ $t("privacyPolicy.rightsText1", lang) }}
          </p>
          <p>
            {{ $t("privacyPolicy.rightsText2", lang) }}
          </p>
          <p>
            {{ $t("privacyPolicy.rightsText3", lang) }}
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PrivacityPolicy",
    metaInfo() {
      return {
        title: this.$t("privacyPolicy.metaTitle", this.lang),
        meta: [
          { name: "description", content: this.$t("privacyPolicy.metaDescription", this.lang) },
          { name: "robots", content: "noindex"}
        ],
        link: [
          { rel: "canonical", href: "https://www.hellobb.net/politica-privacidad" },
          { rel: "alternate", href: "https://www.hellobb.net/fr", hreflang: "fr" },
          { rel: "alternate", href: "https://www.hellobb.net/politica-privacidad", hreflang: "es-es" },
          { rel: "alternate", href: "https://www.hellobb.net/politica-privacidad", hreflang: "es-mx" },
          { rel: "alternate", href: "https://www.hellobb.net/politica-privacidad", hreflang: "es-co" },
          { rel: "alternate", href: "https://www.hellobb.net/politica-privacidad", hreflang: "es-ar" },
          { rel: "alternate", href: "https://www.hellobb.net/politica-privacidad", hreflang: "es-cl" },
          { rel: "alternate", href: "https://www.hellobb.net/politica-privacidad", hreflang: "es-uy" },
          { rel: "alternate", href: "https://www.hellobb.net/politica-privacidad", hreflang: "es-pe" },
        ],
      };
    },
  
    data() {
      return {};
    },
    computed: {
      lang() {
        return this.$route.meta?.lang || this.$i18n.locale;
      }
    },
  
    // Server-side only
    // This will be called by the server renderer automatically
    serverPrefetch() {
      // return the Promise from the action
      // so that the component waits before rendering
    },
  
    // Client-side only
    mounted() {},
  
    methods: {},
  };
  </script>
  
  <style scoped>
  h3 {
    font-weight: bold;
  }
  </style>